interface Props {
  message: string
}

export default function Alert({ message }: Props) {
  return (
    <div role="alert" className="relative p-4 mb-4 text-black rounded-md border border-solid border-green-200 bg-green-100">
      {message}
    </div>
  );
}

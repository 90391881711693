/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: 'primary' | 'secondary' | 'danger'
}

export default function Button({ children, buttonStyle = 'primary', ...props }: Props) {
  const classes = classNames('inline-block py-1 px-3 rounded font-normal text-center whitespace-nowrap align-middle select-none border border-solid transition-colors no-underline disabled:opacity-70 disabled:pointer-events-none', {
    'text-white bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-sky-800': buttonStyle === 'primary',
    'text-white bg-gray-500 border-gray-500 hover:bg-gray-600 hover:border-gray-700': buttonStyle === 'secondary',
    'text-white bg-red-600 border-red-600 hover:bg-red-700 hover:border-red-800': buttonStyle === 'danger',
  });

  return (
    <button {...props} className={classes}>
      {children}
    </button>
  );
}

import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const navClasses = classNames('relative flex flex-wrap items-center justify-between py-2', {
    'bg-emerald-700': window.gon.application_scheme === 'modernwallet',
    'bg-violet-700': window.gon.application_scheme === 'modernbagelry',
  });

  return (
    <nav className={navClasses}>
      <div className="flex items-center justify-between w-full px-3 mx-auto">
        <Link to="/" className="py-1 text-xl no-underline whitespace-nowrap text-white">
          {window.gon.application_title}
        </Link>
      </div>
    </nav>
  );
}
